const initialState = {
    allWfhRequests: [],
    userWfhRequests: [],
    managerWfhRequests: [],
    hodWfhRequests: [],
    employeeProjectsManagers: []
}

const workFromHomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_WFH_REQUESTS':
            return {
                ...state,
                allWfhRequests: action?.payload
            };
        case 'GET_USER_WFH_REQUESTS':
            return {
                ...state,
                userWfhRequests: action.payload
            };
        case 'GET_MANAGER_WFH_REQUESTS':
            return {
                ...state,
                managerWfhRequests: action.payload
            };
        case 'GET_HOD_WFH_REQUESTS':
            return {
                ...state,
                hodWfhRequests: action.payload
            }
        case 'GET_EMPLOYEE_PROJECT_MANAGERS':
            return {
                ...state,
                employeeProjectsManagers: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default workFromHomeReducer;